<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Integrasi Dedicated ChatGPT
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="toggleModal('new_bot')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Bot
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="chatgptQuota == -1" class="alert alert-success" role="alert">
                <div class="alert-content">
                    <p>Batas ChatGPT maksimal anda adalah <b>Unlimited</b></p>
                </div>
            </div>
            <div v-else-if="chatgptQuota == 0" class="alert alert-danger" role="alert">
                <div class="alert-content">
                    <p>Batas ChatGPT maksimal anda adalah <b>{{ chatgptQuota }}</b></p>
                </div>
            </div>
            <div v-else class="alert alert-primary" role="alert">
                <div class="alert-content">
                    <p>Batas ChatGPT maksimal anda adalah <b>{{ chatgptQuota }}</b></p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <ChatGPTTable :device="device" />
                    </div>
                </div>
            </div>
            <modal name="new_bot" :height="'auto'" :width="'50%'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddChatGPT :device="device" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import ChatGPTTable from "@/components/tables/DedicatedChatGPT.vue";
    import AddChatGPT from "@/components/forms/chat_gpt/Add.vue";

    export default {
        components: {
            ChatGPTTable,
            AddChatGPT,
        },
        data() {
            return {
                device: [],
                group_dropdown: [],
                exportJson: [],
                chatgptQuota: 0
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.chatgptQuota = this.$store.state.auth.package.max_chat_gpt

            this.getDevice({
                status: "all"
            })
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                this.device = res.data.data.devices
            },
        },
    };
</script>