<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Profile</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading"
                            class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <label for="">Email</label>
                            <input type="email" v-model="user.email" class="form-control">
                            <br>
                            <label for="">Name</label>
                            <input type="text" v-model="user.name" class="form-control">
                            <br>
                            <label for="">Username</label>
                            <input type="text" v-model="user.username" class="form-control" readonly>
                            <br>
                            <label for="">No Telp</label>
                            <input type="text" v-model="user.phone" class="form-control" readonly>
                            <br>
                            <label for="">Timezone</label>
                            <select class="form-control" v-model="user.timezone">
                                <option value="-12">GMT -12</option>
                                <option value="-11">GMT -11</option>
                                <option value="-10">GMT -10</option>
                                <option value="-9">GMT -9</option>
                                <option value="-8">GMT -8</option>
                                <option value="-7">GMT -7</option>
                                <option value="-6">GMT -6</option>
                                <option value="-5">GMT -5</option>
                                <option value="-4">GMT -4</option>
                                <option value="-3">GMT -3</option>
                                <option value="-2">GMT -2</option>
                                <option value="-1">GMT -1</option>
                                <option value="0">GMT 0</option>
                                <option value="1">GMT +1</option>
                                <option value="2">GMT +2</option>
                                <option value="3">GMT +3</option>
                                <option value="4">GMT +4</option>
                                <option value="5">GMT +5</option>
                                <option value="6">GMT +6</option>
                                <option value="7">GMT +7</option>
                                <option value="8">GMT +8</option>
                                <option value="9">GMT +9</option>
                                <option value="10">GMT +10</option>
                                <option value="11">GMT +11</option>
                                <option value="12">GMT +12</option>
                            </select>
                            <br>
                            <label> Account API Key </label>
                            <div class="input-group mb-3">
                                <input ref="account_api_key" type="text" v-model="user.token" class="form-control" id="token" readonly>
                                <div class="input-group-append">
                                    <button id="copy" class="clipboard btn btn-outline-secondary" type="button" v-on:click="copyAPIKey">Copy</button>
                                    <a v-on:click="resetApiKey" class="btn btn-success btn-block"> Reset API Key </a>
                                </div>
                            </div>
                            <br>
                            <label for="">Link Afiliasi</label>
                            <input type="text" class="form-control" v-model="user.reff_url" readonly>
                            <br>
                            <label for="">Password Baru (Kosongkan Jika Tidak Ingin Mengganti Password)</label>
                            <input type="text" class="form-control" v-model="user.password">
                            <br>
                            <button v-on:click="updateUser" class="btn btn-outline-success btn-block">Simpan</button>
                            <div v-if="user.google_api_token == null">
                                <hr>
                                <a v-on:click="getOauthURL" class="btn btn-dark btn-block"> <img width="20px" style="margin-top:-5px; margin-right:8px" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"/> Koneksikan Dengan Google Contact </a>
                            </div>
                            <div v-else>
                                <hr>
                                <a class="btn btn-light btn-block"> <img width="20px" style="margin-top:-5px; margin-right:8px" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"/> Hapus Koneksi Google Contact </a>
                            </div>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                isLoading: true,
                user: {},
                pass:""
            };
        },
        mounted() {
            this.loadData()
        },
        methods: {
            async loadData() {
                try {
                    let detail = await this.$store.dispatch("user_detail")
                    this.user = detail.data.data.user
                    this.user.reff_url = process.env.VUE_APP_FRONTEND_URL + "/register?reff=" + this.user.referral
                    this.pass = this.user.password
                    this.user.password = ""
                    this.isLoading = false
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/dashboard")
                }
            },
            async updateUser() {
                if(this.user.password == "")
					this.user.password = this.pass
                try {
                    let response = await this.$store.dispatch('user_update', {
                        ...this.user,
                        timezone: parseInt(this.user.timezone),
                    })
                    this.$toast.success(response.data.message);
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async resetApiKey() {
                try {
                    let response = await this.$store.dispatch('user_reset_api_key')
                    this.$toast.success(response.data.message);
                    this.loadData()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async getOauthURL(){
                try {
                    let response = await this.$store.dispatch('user_get_oauth')
                    
                    var URL = response.data.data.url
                    window.location.href = URL
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            copyAPIKey() {
                this.$toast.success("Copy API Key Berhasil");
                this.$refs["account_api_key"].focus();
                document.execCommand('copy');
            },
        },
    };
</script>