<template>
    <div>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="table.total_records"
        :isLoading.sync="table.isLoading"
        :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }"
        :columns="table.columns"
        :rows="table.rows"
      >
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'total_token'">
                {{ convertToRupiah(props.row.total_token) }}
            </span>
            <span v-else-if="props.column.field == 'question'">
                {{ cutString(props.row.question) }}
            </span>
            <span v-else-if="props.column.field == 'answer'">
              {{ cutString(props.row.answer) }}
            </span>
          <span v-else-if="props.column.field == 'action'">
            <a v-on:click="viewHistory({ question: props.row.question, answer: props.row.answer, total_token: props.row.total_token })">
              <a class="btn btn-primary text-white margin5">Detail</a>
            </a>
            <a
              v-on:click="deleteChatGPTHistory(props.row.id)"
              class="btn btn-danger text-white margin5"
            >
              Hapus
            </a>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>

  export default {
    props: ['id', "showToken"],
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [
            {
              label: "Nomor",
              field: "number",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "20%",
            },
            {
              label: "Pesan",
              field: "question",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "25%",
            },
            {
              label: "Jawaban",
              field: "answer",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "25%",
            },
            {
              label: "Penggunaan",
              field: "total_token",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [
              {
                field: "",
                type: "",
              },
            ],
            page: 1,
            limit: 10,
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      cutString(string){
                if(string == null || string == "") return ""
                
                if(string.length > 40){
                    string = string.slice(0, 40) + "..."
                }

                return string
            },
      async deleteChatGPTHistory(id) {
        try {
          let response = await this.$store.dispatch("chatgpt_delete_history", {id: this.id, history_id: id});
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign(
          {},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({page: params.currentPage});
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({limit: params.currentPerPage});
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [
            {
              type: params.sortType,
              field: this.table.columns[params.columnIndex].field,
            },
          ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.chatgpt_history;

        //   for (let i = 0; i < this.table.rows.length; i++) {
        //     this.table.rows[i].count_device = JSON.parse(this.table.rows[i].device).length ?? 0
        //   }
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        param.chatgpt_id = this.id
        return await this.$store.dispatch("chatgpt_history_list", param);
      },
      async viewHistory(data) {
        this.$root.$emit("history_data", data)
        this.$root.$emit("toggle_modal", "view_history")
      },
      convertToRupiah(value) {
          if (typeof value !== 'number') return 'Rp 0';
          return `Rp ${value.toLocaleString('id-ID', { 
            style: 'currency', 
            currency: 'IDR', 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 5 
        }).replace('Rp', '').trim()}`;     
      }
    },
  };
  </script>
  
  <style scoped>
  .margin5 {
    margin: 5px;
  }
  </style>
  