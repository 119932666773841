<template>
    <div>
        <center>
            <h4>TopUp Kuota</h4>
        </center>
        <br>
        <div v-if="step == 1">
            <form v-on:submit.prevent="nextStep" enctype="multipart/form-data">
                <label> Jumlah Kuota Respon (Rp 20 / Respon)</label>
                <money class="form-control" v-model="form.quota" v-bind="money"></money>
                <br>
                <i>Minimal transaksi 1.250 Respon</i>
                <br>
                <hr>
                <div class="float-right">
                    <table style="border: none;">
                        <tr>
                            <td>Total Harga</td>
                            <td>&nbsp;:&nbsp;</td>
                            <td><b>{{ formatRupiah(form.quota * 20) }}</b></td>
                        </tr>
                        <!-- <tr>
                            <td>Saldo </td>
                            <td>&nbsp;:&nbsp;</td>
                            <td>{{ formatRupiah(user_balance) }}</td>
                        </tr> -->
                    </table>
                    <br>
                </div>
                <!-- <label for="">Pesan Registrasi</label>
                <CustomTextArea v-model="form.message"></CustomTextArea>
                <i>Anda bisa memanggil variabel menggunakan [] (Bracket)</i>
                <br>
                <i>Variabel Tersedia : {{ available_variable }}</i>
                <br> -->
                <br>
                <button v-if="form.quota >= 1250" class="btn btn-success btn-block">Beli</button>
                <button v-else class="btn btn-danger btn-block" disabled>Belum Mencapai Minimal Pembelian</button>
            </form>
        </div>
        <div v-else>
            <div>
                <table style="border: none;">
                    <tr>
                        <td>Total Harga</td>
                        <td>&nbsp;:&nbsp;</td>
                        <td><b>{{ formatRupiah(form.quota * 20) }}</b></td>
                    </tr>
                    <!-- <tr>
                        <td>Saldo </td>
                        <td>&nbsp;:&nbsp;</td>
                        <td>{{ formatRupiah(user_balance) }}</td>
                    </tr> -->
                </table>
            </div>
            <hr>
            <center>
                <div class="row">
                    <div class="col-md-6">
                    <button v-for="(value, index) in payment_channel[0]" v-bind:key="index"
                        class="btn btn-block text-white btn-topup" :style="'background-color:' + value.class" name="method"
                        v-on:click="buy(value.channel_code)" :disabled="form.low || form.high"> {{ value.channel_name }}
                    </button>
                    </div>
                    <div class="col-md-6">
                        <button v-for="(value, index) in payment_channel[1]" v-bind:key="index"
                            class="btn btn-block text-white btn-topup" :style="'background-color:' + value.class" name="method"
                            v-on:click="buy(value.channel_code)" :disabled="form.low || form.high"> {{ value.channel_name }}
                        </button>
                    </div>
                    <div class="col-md-12" style="margin-top:10px">
                        <button class="btn-topup btn btn-block text-white" style="background-color:#3786c5" name="method"
                            v-on:click="buy('saldo')" :disabled="form.low || form.high"> Saldo Akun
                        </button>
                    </div>
                </div>
            </center>
        </div>
    </div>
</template>

<script>
    import { Money } from 'v-money'

    export default {
        props: ['id', "dataset_id"],
        components: {
            Money
        },
        data() {
            return {
                step: 1,
                form: {
                    quota: null
                },
                money: {
                    thousands: '.',
                    precision: 0,
                },
                user_balance: 0,
                payment_channel: [],
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }

            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.fetchUser()
            this.fetchPaymentChannel()
        },
        methods: {
            async fetchPaymentChannel() {
                try {
                    let payment_channel = await this.$store.dispatch('payment_channel')
                    this.payment_channel = this.chunkArray(payment_channel.data.data.payment_channel, 2)
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            chunkArray(arr, n) {
                let chunkLength = Math.max(arr.length / n, 1);
                let chunks = [];
                for (let i = 0; i < n; i++) {
                    if (chunkLength * (i + 1) <= arr.length) chunks.push(arr.slice(chunkLength * i, chunkLength * (i +
                        1)));
                }
                return chunks;
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            nextStep() {
                this.step = 2
            },
            async buy(channel_code){
                if(this.form.quota < 1250){
                    this.$toast.error("Minimal pembelian 1.250 Respon")
                    return
                }
                
                try{
                    let create = await this.$store.dispatch('chatgpt_topup', { chatgpt_id: this.id, quota: parseInt(this.form.quota), channel_code } )

                    if(create?.data?.data?.transaction?.checkout_url){
                        window.location.replace(create.data.data.transaction.checkout_url)
                    }

                    this.$root.$emit("toggle_modal", "topup")
                    this.$toast.success(create.data.message)
                    this.$root.$emit("refresh_quota", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            },
            formatRupiah(numb) {
                const format = numb.toString().split('').reverse().join('');
                const convert = format.match(/\d{1,3}/g);
                const rupiah = 'Rp ' + convert.join('.').split('').reverse().join('')
                return rupiah
            },
            async fetchUser() {
                try {
                    let user_data = await this.$store.dispatch('user_detail')
                    this.user_balance = user_data.data.data.user.balance
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
        },
    };
</script>

<style scoped>
    

    table {
        border: 1px solid;
    }
</style>