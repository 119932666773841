<template>
    <div>
        <button class="btn btn-block btn-success btn-sm mb-2" v-on:click="addDevice">Tambah Device</button>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="table.total_records"
        :isLoading.sync="table.isLoading"
        :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }"
        :columns="table.columns"
        :rows="table.rows"
      >
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'status'">
                <span v-if="props.row.status == '1'" class="badge badge-success text-white">Aktif</span>
                <span v-else class="badge badge-danger text-white">Nonaktif</span>

            </span>
          <span v-else-if="props.column.field == 'action'">
            <a v-on:click="changeStatus(props.row.device_id, props.row.status != '1' ? 1 : 0)">
              <a v-if="props.row.status == '1'"  class="btn btn-sm btn-primary text-white margin5">Nonaktifkan</a>
              <a v-else  class="btn btn-sm btn-primary text-white margin5">Aktifkan</a>
            </a>
            <a
              v-on:click="deleteChatGPTDevice(props.row.device_id)"
              class="btn btn-sm btn-danger text-white margin5"
            >
              Hapus
            </a>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>

  export default {
    props: ['id'],
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [
            {
              label: "Device",
              field: "device.name",
              // filterOptions: {
              //   enabled: true,
              // },
              sortable: false,
              width: "40%",
            },
            {
              label: "Status",
              field: "status",
              sortable: false,
              width: "40%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [
              {
                field: "",
                type: "",
              },
            ],
            page: 1,
            limit: 10,
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      cutString(string){
                if(string == null || string == "") return ""
                
                if(string.length > 40){
                    string = string.slice(0, 40) + "..."
                }

                return string
            },
      async deleteChatGPTDevice(device_id) {
        try {
          let response = await this.$store.dispatch("chatgpt_device_delete", {chatgpt_id: this.id, device_id: device_id});
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign(
          {},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({page: params.currentPage});
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({limit: params.currentPerPage});
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [
            {
              type: params.sortType,
              field: this.table.columns[params.columnIndex].field,
            },
          ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.chatgpt;

        //   for (let i = 0; i < this.table.rows.length; i++) {
        //     this.table.rows[i].count_device = JSON.parse(this.table.rows[i].device).length ?? 0
        //   }
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        param.chatgpt_id = this.id
        return await this.$store.dispatch("chatgpt_device_list", param);
      },
      async addDevice() {
        this.$root.$emit("toggle_modal", "add_device")
        // this.$root.$emit("edit_dataset", id)
      },
      async changeStatus(device_id, status) {
        try {
          let response = await this.$store.dispatch("chatgpt_device_change_status", {chatgpt_id: this.id, device_id: device_id, status: status});
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .margin5 {
    margin: 5px;
  }
  </style>
  