<template>
    <div>
        <center>
            <h4>Detail History</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addDataset" enctype="multipart/form-data">
            <label> Pesan </label>
            <textarea v-model="form.question" rows="5" class="form-control" readonly></textarea>
            <br>
            <label> Jawaban </label>
            <textarea v-model="form.answer" rows="5" class="form-control" readonly></textarea>
            <br>
            <label> Penggunaan </label>
            <input type="text" v-model="form.total_token" class="form-control" readonly>

            <!-- <label for="">Pesan Registrasi</label>
            <CustomTextArea v-model="form.message"></CustomTextArea>
            <i>Anda bisa memanggil variabel menggunakan [] (Bracket)</i>
            <br>
            <i>Variabel Tersedia : {{ available_variable }}</i>
            <br> -->
        </form>
    </div>
</template>

<script>

    export default {
        props: ['data'],
        components: {
        },
        data() {
            return {
                form: {
                    question: "",
                    answer: "",
                    total_token: ""
                },
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }

            this.$root.$on("toggle_modal", this.fnToggleModal);

            if(this.data){
                this.form.question = this.data.question
                this.form.answer = this.data.answer
                this.form.total_token = this.convertToRupiah(this.data.total_token)
            }
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            convertToRupiah(value) {
                if (typeof value !== 'number') return 'Rp 0';
                return `Rp ${value.toLocaleString('id-ID', { 
                    style: 'currency', 
                    currency: 'IDR', 
                    minimumFractionDigits: 0, 
                    maximumFractionDigits: 5 
                }).replace('Rp', '').trim()}`;     
            }
        },
    };
</script>

<style scoped>
    

    table {
        border: 1px solid;
    }
</style>