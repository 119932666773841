<template>
    <div>
      <div style="text-align: center">
        <h3>Tambah Integrasi Chat GPT</h3>
      </div>
      <br />
      <form v-on:submit.prevent="addChatGPT">
        <div class="row">
            <div class="col-md-12">
                <label>Nama Bot</label>
                <input type="text" class="form-control" v-model="form.name" autofocus required />
            </div>
            <div class="col-md-12"  style="margin-top:10px">
                <label for="">Reenable Ai Duration (menit)</label>
                <input type="number" placeholder="" class="form-control" v-model="form.reenable_ai_duration" autofocus required />
                <i>*Waktu percakapan kembali di Respon AI, setelah user chat dengan cs asli / jika spam terdeteksi</i>
                <br>
                <br>
                <!-- <input name="hide" class="checkbox" type="checkbox" id="check-1" v-model="form.disable_during_office_hour">
                <label for="check-1">
                    <span class="checkbox-text" style="margin-left: 5px;">Disable During Office Hour</span>
                </label>
                <br> -->
                <input name="hide" class="checkbox" type="checkbox" id="check-2" v-model="form.enable_memory">
                <label for="check-2">
                    <span class="checkbox-text" style="margin-left: 5px;">Enable Memory</span>
                </label>
                <br>
            </div>
        </div>
        <br>
        <div class="button-group d-flex pt-25">
            <button type="submit" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
              Tambah
            </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>

  export default {
    props: ['device'],
    components: {
        },
    data() {
      return {
        form: {
            device: [],
            name: "",
            api_key: "",
            models: "gpt-4o-mini",
            enable_memory: true,
            max_token_chat: -1,
            daily_max_token_number: -1,
            daily_max_token_global: -1,
            temperature: 0.5,
            top_p: 0.5,
            disable_during_office_hour: false,
            fallback_message: "",
            reenable_ai_duration: 5,
            custom_prompt: "",
        },
      };
    },
    mounted() {
  
    },
    methods: {
      async addChatGPT(){
        try{
            let request = this.form
            console.log(request)
            //   request.multidevice = parseInt(request.multidevice)
            request.device_id = request.device.id
            request.enable_memory = request.enable_memory ? 1 : 0
            request.disable_during_office_hour = request.disable_during_office_hour ? 1 : 0
            request.max_token_chat = parseInt(request.max_token_chat)
            request.daily_max_token_number = parseInt(request.daily_max_token_number)
            request.daily_max_token_global = parseInt(request.daily_max_token_global)
            request.reenable_ai_duration = parseInt(request.reenable_ai_duration)
            request.temperature = parseFloat(request.temperature)
            request.top_p = parseFloat(request.top_p)
            request.type = 2
            let response = await this.$store.dispatch('chatgpt_create', request)
            
            this.$toast.success(response.data.message);
            this.$root.$emit("toggle_modal", "new_bot")
            this.$root.$emit("refresh_table", true)
        }catch(err){
          this.$toast.error(err.response.data.message);
        }
      },
    },
  };
  </script>
  
