<template>
    <div>
        <center>
            <h4>Tambah Device</h4>
        </center>
        <br>
        <form v-on:submit.prevent="saveDevice" enctype="multipart/form-data">
            <label> Device </label>
                <multiselect v-model="form.device" tag-placeholder="Pilih device"
                    placeholder="Pilih device" label="name" track-by="id" :options="device"
                    :multiple="false"></multiselect>
            <br>
            <!-- <label for="">Pesan Registrasi</label>
            <CustomTextArea v-model="form.message"></CustomTextArea>
            <i>Anda bisa memanggil variabel menggunakan [] (Bracket)</i>
            <br>
            <i>Variabel Tersedia : {{ available_variable }}</i>
            <br> -->
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    export default {
        props: ['device', 'id'],
        components: {
            Multiselect
        },
        data() {
            return {
                form: {
                    device: {},
                },
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }

            this.$root.$on("toggle_modal", this.fnToggleModal);

        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            async saveDevice(){
                try{
                    let create = await this.$store.dispatch('chat_gpt_add_device', { chatgpt_id: this.id, device_id:this.form?.device?.id } )
                    this.$root.$emit("toggle_modal", "add_device")

                    this.$toast.success(create.data.message)
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    table {
        border: 1px solid;
    }
</style>